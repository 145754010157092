import service from "@/http/httpService";

// 钱包
export const webUserGetAssetsList = (params) => service.post('/api/app/assets/assetsList', { data: params, needEn: true })
// 查询资金记录
export const getAssetsRecords = (params) => service.post('/api/app/assets/assetsRecordList', { data: params, needEn: true })

// 充值
export const onDeposit = (params) => service.post('/api/app/deposit/deposit', { data: params, needEn: true })

// 查询提现配置
export const withdrawConfig = (params) => service.post('/api/app/withdrawal/configList', { data: params, needEn: true })
// 查询资金记录
export const onWithdraw = (params) => service.post('/api/app/withdrawal/withdrawal', { data: params, needEn: true })

// 查询提现部分配置
export const getWithdrawInfo = (params) => service.post('/api/app/withdrawal/withdrawalInfo', { data: params, needEn: true })