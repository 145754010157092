// 千分位
const addCommas = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const getDecimalPlaces = (num) => {
  const numStr = num.toString();
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  } else {
    return 0; // 如果没有小数部分，返回0
  }
}

// 保留小数位
const numberFixed = (num, len = 6) => {
  let decimalPlaces = getDecimalPlaces(num);
  let res = num;
  if(decimalPlaces > len) {
    res = Number(num).toFixed(len)
  } 
  return res;
}

const fixNum = (num, len = 6) => {
  return numberFixed(num, len)
}

export { addCommas, numberFixed, fixNum }