/**
 *  IP 信息
 */
let baseUrl, imageUrl, socketUrl ;

// const origin = 'http://217.79.252.98:24501';
const origin = 'https://starlea.io';

switch (process.env.NODE_ENV) {
  case "development":

    baseUrl = origin + "/art-star/";
    imageUrl = origin + "/art-star/";
    socketUrl = 'wss://starlea.io/ez';
    break;
  case "production":

    baseUrl = "https://starlea.io/art-star/";
    imageUrl = "https://starlea.io/art-star/";
    socketUrl = 'wss://starlea.io/ez';
    break;
}

export { baseUrl, imageUrl, socketUrl };
