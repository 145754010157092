<template>
  <div class="footer">
    <div class="footer-h5 show">
      <div class="inner flex align-center">
        <div class="flex-1 item" 
          :class="item.path === $route.path ? 'select': ''"
          v-for="(item, index) in list" :key="index"
          @click="$router.replace(item.path)"
        >
          <!-- <a-icon :type="item.icon" style="color: #27173E;" /> -->
          <img class="item-img" :src="item.path === $route.path ? item.select : item.icon" alt="">
          <div>{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Home from '@/assets/img/home.png';
import Trade from '@/assets/img/handshake.png';
import Art from '@/assets/img/blockchain-3.png';
import Assets from '@/assets/img/coins.png';
import Profile from '@/assets/img/user.png';
import HomeSelect from '@/assets/img/home-select.png';
import TradeSelect from '@/assets/img/handshake-select.png';
import ArtSelect from '@/assets/img/blockchain-3-select.png';
import AssetsSelect from '@/assets/img/coins-select.png';
import ProfileSelect from '@/assets/img/user-select.png';
export default {
  data() {
    return {
      list: [
        { label: this.$t('Common.首页'), icon: Home, select: HomeSelect, path: '/home' }, 
        { label: this.$t('Common.交易'), icon: Trade, select: TradeSelect, path: '/trade' }, 
        { label: this.$t('Common.生态'), icon: Art, select: ArtSelect, path: '/art' }, 
        { label: this.$t('Common.资产'), icon: Assets, select: AssetsSelect, path: '/wallet' }, 
        { label: this.$t('Common.我的'), icon: Profile, select: ProfileSelect, path: '/profile' }, 
      ],
      
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

.footer-h5 {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 99999;
  width: 500px;
  height: 75px;
  padding: 12px 0;
  background-color: #000;

  .inner {
    width: 100%;
  }

  .item {
    text-align: center;
    cursor: pointer;

    .item-img {
      height: 25px;
    }

    div {
      margin-top: 5px;
      font-size: 13px;
      color: #fff;
    }

    &.select {
      div {
        color: #ffc000;
      }
    }
  }
}


@media screen and (max-width: 576px) {
  .footer-h5 {
    width: 100%;
  }
}

</style>