import service from "@/http/httpService";

// 获取国家列表
export const webUserGetCountryList = (params) => service.post("/api/country/list", { data: params, needEn: true });
// 登录
export const webUserLogin = (params) => service.post("/api/app/user/login", { data: params, needEn: true });
// 获取用户信息
export const webUserGetUserInfo = (params) => service.post("/api/app/user/userInfo", { data: params, needEn: true });

// 登录认证
export const webUserLoginAuth = (params) => service.post("/api/app/user/loginAuth", { data: params, needEn: true });

// App获取邀请链接
export const webUserGetInviteLink = (params) => service.post("/api/app/user/invitationLink", { data: params, needEn: true });


