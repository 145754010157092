import service from "@/http/httpService";

// 兑换
export const onConvert = (params) => service.post('/api/app/convert/convert', { data: params, needEn: true })
// 兑换配置
export const convertConfig = (params) => service.post('/api/app/convert/convertConfigList', { data: params, needEn: true })
// 兑换记录
export const getConvertRecords = (params) => service.post('/api/app/convert/convertOrderList', { data: params, needEn: true })

// 矿池信息
export const getInvestInfo = (params) => service.post('/api/app/assets/sellRtInvestInfo', { data: params, needEn: true, notLoading: true })
// 卖出RT
export const onSellRt = (params) => service.post('/api/app/assets/sellRtInvest', { data: params, needEn: true })
// 卖出ART
export const onSellArt = (params) => service.post('/api/app/assets/sellTarInvest', { data: params, needEn: true })