import Coin from './Coin.json';
import RecordType from './RecordType.json';
import Art from './Art.json';
import Assets from './Assets.json';
import Profile from './Profile.json';
import Trade from './Trade.json';
import Common from './Common.json';
import Home from './Home.json';

export default {
  Coin: { ...Coin, },
  RecordType: { ...RecordType, },
  Art: { ...Art, },
  Assets: { ...Assets, },
  Profile: { ...Profile, },
  Trade: { ...Trade, },
  Common: { ...Common, },
  Home: { ...Home, },
}